
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { TYPE_OF_DEVICE_ACTIONS } from '@/store/actions'
import { ITypeOfDevice } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onRemove', 'update:onSelect'],
})
export default class TypeOfDeviceById extends Vue {
  @Prop({ default: '' })
  id!: string

  get loading() {
    return this.$store.state.typeOfDevices.loading
  }

  get typeOfDevice(): ITypeOfDevice[] {
    return this.$store.getters.typeOfDevices
  }

  @Emit('update:onRemove')
  onRemove() {
    return this.id
  }

  @Emit('update:onSelect')
  onSelect() {
    return this.id
  }

  get displayText() {
    return this.typeOfDevice.find((typeOfDevice) => typeOfDevice.id === this.id)?.name
  }

  async created() {
    if (!this.typeOfDevice.length) {
      await this.$store.dispatch(TYPE_OF_DEVICE_ACTIONS.LOAD_LIST)
    }
  }
}
