
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IFileItem, IOrderElectronicDevice, IUser } from '@/utils/types'
import { UploadOutlined } from '@ant-design/icons-vue'
import cloneDeep from 'lodash/cloneDeep'
import TypeOfDeviceSelectBox from '@/components/type-of-device/TypeOfDeviceSelectBox.vue'
import moment from 'moment'
import FireOrderElectronicDevice from '@/services/order-electronic-devices'

@Options({
  components: { TypeOfDeviceSelectBox, UploadOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class OrderElectronicDeviceFormModal extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false

  fileList: IFileItem[] = []
  uploading = false

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form: IOrderElectronicDevice = {
    deviceName: '',
    typeOfDeviceId: '',
    deviceInformation: '',
    fireChangeType: '',
    user: '',
    isSendMail: false,
    expiredAt: moment(),
    dateAt: moment(),
    createdAt: 0,
    updatedAt: 0,

  }

  rules = {
    deviceName: [this.requiredRule],
    typeOfDevice: [this.requiredRule],
  }

  get modalTitle() {
    return this.isNew ? 'Add new order electronic device' : 'Edit order electronic device'
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isNew() {
    return !this.id
  }

  fixDataToSave() {
    const dataToSave = cloneDeep(this.form)
    if (dataToSave.expiredAt) {
      dataToSave.expiredAt = (dataToSave.expiredAt as moment.Moment).unix()
    }

    if (dataToSave.isSendMail) {
      dataToSave.isSendMail = false
    }

    if (dataToSave.dateAt) {
      dataToSave.dateAt = (dataToSave.dateAt as moment.Moment).unix()
    }

    return dataToSave
  }

  rawToFormData(rawData: IOrderElectronicDevice) {
    if (rawData.expiredAt) {
      rawData.expiredAt = moment.unix(rawData.expiredAt as number)
    }

    if (rawData.dateAt) {
      rawData.dateAt = moment.unix(rawData.dateAt as number)
    }

    return rawData
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const dataToSave = this.fixDataToSave()
    this.loading = true
    if (!this.id) {
      await FireOrderElectronicDevice.add(dataToSave)
    } else {
      await FireOrderElectronicDevice.update(dataToSave)
    }

    message.success('Saved successfully')
    this.loading = false
    this.onCancel()
  }

  async getOrderElectronicDevice() {
    return await FireOrderElectronicDevice.get(this.id)
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      const orderElectronicDevice = await this.getOrderElectronicDevice()
      const dataForm = this.rawToFormData(orderElectronicDevice)
      this.form = { ...this.form, ...dataForm }
    }
  }
}
