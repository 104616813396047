
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ITypeOfDevice } from '@/utils/types'
import FireTypeOfDevice from '@/services/type-of-devices'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class TypeOfDeviceSelectBox extends Vue {
  @Prop()
  selectedIds!: string[]

  loading = false
  selectedIdsLocal: string[] = []
  searchQuery = ''

  typeOfDevices: ITypeOfDevice[] = []

  @Emit('update:onSelect')
  onSelect() {
    return this.selectedIdLocal
  }

  async getTypeOfDevice() {
    this.typeOfDevices = await FireTypeOfDevice.list()
  }

  beforeDestroy() {
    this.typeOfDevices = []
  }

  async created() {
    await this.getTypeOfDevice()
    this.selectedIdsLocal = this.selectedIds
  }
}
