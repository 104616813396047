<template>
  <a-spin :spinning="loading">
    <a-select v-model:value="selectedIdsLocal" @change="onSelect" size="large" placeholder="Type of device">
      <a-select-option v-for="typeOfDevice of typeOfDevices" :key="typeOfDevice.id" :value="typeOfDevice.id" >
        {{ typeOfDevice.name }}
      </a-select-option>
    </a-select>
  </a-spin>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ITypeOfDevice } from '@/utils/types'
import FireTypeOfDevice from '@/services/type-of-devices'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class TypeOfDeviceSelectBox extends Vue {
  @Prop()
  selectedIds!: string[]

  loading = false
  selectedIdsLocal: string[] = []
  searchQuery = ''

  typeOfDevices: ITypeOfDevice[] = []

  @Emit('update:onSelect')
  onSelect() {
    return this.selectedIdLocal
  }

  async getTypeOfDevice() {
    this.typeOfDevices = await FireTypeOfDevice.list()
  }

  beforeDestroy() {
    this.typeOfDevices = []
  }

  async created() {
    await this.getTypeOfDevice()
    this.selectedIdsLocal = this.selectedIds
  }
}
</script>
