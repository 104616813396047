<template>
  <a-modal class="ant-modal--xl" :visible="modalVisible" @cancel="onCancel" :title="modalTitle" :loading="loading">
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <div class="flex gap-4">
        <div class="flex-1">
          <a-form-item label="Device name" name="deviceName">
            <a-input size="large" v-model:value="form.deviceName" autocomplete="deviceName" placeholder="Device name" />
          </a-form-item>
        </div>
      </div>

      <div class="flex gap-4">
        <div class="flex-1">
          <a-form-item label="Type of devices">
            <TypeOfDeviceSelectBox
              label="Type of devices"
              :selectedIds="form.typeOfDeviceId"
              @update:onSelect="(ids) => (form.typeOfDeviceId = ids)"
            />
          </a-form-item>
        </div>
        <div class="flex-1">
          <a-form-item label="Quantity" name="quantity">
            <a-input-number size="large" v-model:value="form.quantity" :min="1" :max="10" style="width: 100%" />
          </a-form-item>
        </div>
        <div class="flex-1">
          <a-form-item label="Date" name="dateAt">
            <a-date-picker size="large" v-model:value="form.dateAt" placeholder="YYYY/MM/DD" />
          </a-form-item>
        </div>
        <div class="flex-1">
          <a-form-item label="Expired date" name="expiredAt">
            <a-date-picker size="large" v-model:value="form.expiredAt" placeholder="YYYY/MM/DD" />
          </a-form-item>
        </div>
      </div>

      <a-form-item label="User(if any)" name="user">
        <a-input size="large" v-model:value="form.user" autocomplete="phone" placeholder="User" />
      </a-form-item>

      <a-form-item label="Device Information (Link)" name="deviceInformation" class="mt-4">
        <a-textarea
          v-model:value="form.deviceInformation"
          autocomplete="deviceInformation"
          placeholder="Device Information (Link)"
          :rows="4"
        />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="back" type="default" @click="onCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmit"> Save </a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { IFileItem, IOrderElectronicDevice, IUser } from '@/utils/types'
import { UploadOutlined } from '@ant-design/icons-vue'
import cloneDeep from 'lodash/cloneDeep'
import TypeOfDeviceSelectBox from '@/components/type-of-device/TypeOfDeviceSelectBox.vue'
import moment from 'moment'
import FireOrderElectronicDevice from '@/services/order-electronic-devices'

@Options({
  components: { TypeOfDeviceSelectBox, UploadOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class OrderElectronicDeviceFormModal extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false

  fileList: IFileItem[] = []
  uploading = false

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form: IOrderElectronicDevice = {
    deviceName: '',
    typeOfDeviceId: '',
    deviceInformation: '',
    fireChangeType: '',
    user: '',
    isSendMail: false,
    expiredAt: moment(),
    dateAt: moment(),
    createdAt: 0,
    updatedAt: 0,

  }

  rules = {
    deviceName: [this.requiredRule],
    typeOfDevice: [this.requiredRule],
  }

  get modalTitle() {
    return this.isNew ? 'Add new order electronic device' : 'Edit order electronic device'
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isNew() {
    return !this.id
  }

  fixDataToSave() {
    const dataToSave = cloneDeep(this.form)
    if (dataToSave.expiredAt) {
      dataToSave.expiredAt = (dataToSave.expiredAt as moment.Moment).unix()
    }

    if (dataToSave.isSendMail) {
      dataToSave.isSendMail = false
    }

    if (dataToSave.dateAt) {
      dataToSave.dateAt = (dataToSave.dateAt as moment.Moment).unix()
    }

    return dataToSave
  }

  rawToFormData(rawData: IOrderElectronicDevice) {
    if (rawData.expiredAt) {
      rawData.expiredAt = moment.unix(rawData.expiredAt as number)
    }

    if (rawData.dateAt) {
      rawData.dateAt = moment.unix(rawData.dateAt as number)
    }

    return rawData
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const dataToSave = this.fixDataToSave()
    this.loading = true
    if (!this.id) {
      await FireOrderElectronicDevice.add(dataToSave)
    } else {
      await FireOrderElectronicDevice.update(dataToSave)
    }

    message.success('Saved successfully')
    this.loading = false
    this.onCancel()
  }

  async getOrderElectronicDevice() {
    return await FireOrderElectronicDevice.get(this.id)
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      const orderElectronicDevice = await this.getOrderElectronicDevice()
      const dataForm = this.rawToFormData(orderElectronicDevice)
      this.form = { ...this.form, ...dataForm }
    }
  }
}
</script>
